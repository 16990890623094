import axios from 'axios';
// import { fakeFireDetail, fakeFires } from './fake-fires';

export interface FireList {
  count: number;
  next: string | null;
  previous: string | null;
  results: Fire[];
}

export interface Fire {
  id: number;
  latitude: number;
  longitude: number;
  timestamp: string;
}

export interface FireDetail {
  video: string;
  image: string;
  latitude: number;
  longitude: number;
  timestamp: string;
  area: 'California' | 'Brazil';
  cause: string;
  time_graph_pts: string[];
  cloud_graph_pts: boolean[];
  diff_graph_pts: number[];
  pred_graph_pts: number[];
  actual_7_graph_pts: number[];
}

export const getFiresList = async (
  area_name = 'California',
  page_number = 1
) => {
  // return fakeFires;

  const res = await axios.get<FireList>(
    `/api/fires/?area_name=${area_name}&page=${page_number}`
  );

  return res.data;
};

export const getFireDetail = async (fire_id: string) => {
  // return fakeFireDetail;

  const res = await axios.get<FireDetail[]>(`/api/fire/?id=${fire_id}`);

  return res.data;
};
