import React, { useEffect, useState } from 'react';
import { useAuth } from '../Auth';

interface CardProps {
  title: string;
  subtitle?: string; // Optional subtitle
  text?: string;
  link1: string;
  link2?: string;
  link1Text: string;
  link2Text?: string;
}

const Card: React.FC<CardProps> = ({
  title,
  subtitle,
  text,
  link1,
  link2,
  link1Text,
  link2Text,
}) => {
  return (
    <div className="card text-white bg-dark mb-3" style={{ width: '18rem' }}>
      <div className="card-body">
        <h5 className="card-title">{title}</h5>
        {subtitle && (
          <h6 className="card-subtitle mb-2 ">{subtitle}</h6>
        )}
        {text && <p className="card-text">{text}</p>}
        <a href={link1}  className="btn background-primary">
          {link1Text}
        </a>
        {link2 && (
          <a href={link2} className="card-link">
            {link2Text}
          </a>
        )}
      </div>
    </div>
  );
};

const fnnbackgroundcard = {
    title: "Detector Monitoring",
    subtitle: "Status and Files",
    link1: "/fnnbackend",
    link1Text: "Check Detectors",
  }


const LoginRoutes: React.FC = () => {
  const { isAuthenticated, getPermissions } = useAuth();
  const [permissionGroups, setPermissionGroups] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    const fetchPermissions = async () => {
      const permissions = await getPermissions(); // Await the promise
      setPermissionGroups(permissions || {});
    };

    if (isAuthenticated) {
      fetchPermissions();
    }
  }, [isAuthenticated, getPermissions]);


  return (
    <div style={{width: "100%"}}>
      {isAuthenticated && (
        <div className="flex justify-center">
          {permissionGroups.DetectorTeam && <Card {...fnnbackgroundcard}/>}
        </div>
      )}
    </div>
  );
};

export default LoginRoutes;
