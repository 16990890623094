/* eslint-disable @typescript-eslint/no-var-requires */
import { useLocation } from 'react-router-dom';
import React from 'react'
import Header from './components/header';
import Footer from './components/footer';
import ScrollToTop from './components/scroll-to-top';
import SubscribeForm from './components/subscribe-form';
import { error } from 'console';

interface Props {
  children: React.ReactNode;
}


function App(props: Props) {
  const location = useLocation();

  return (
    <div className="relative bg-black">
      <ScrollToTop />
      <div className="absolute inset-0">
        <div className="relative min-h-screen bg-cover bg-black">
          <Header />
          <main className="">
            {props.children}
            {(location.pathname === '/' ||
              location.pathname.includes('/hrl-')) && <SubscribeForm />}
          </main>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default App;
