import React, { useState, useRef, useEffect } from 'react';
import './App.css';
import ReportView from './pages/ReportView';
// import MapView from "./pages/MapView.tsx";
import 'bootstrap/dist/css/bootstrap.min.css';
import L from 'leaflet';
import { useAuth } from '../Auth';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from './components/LoadingSpinner'; // Import the loading spinner

// Define the structure of the object in the detector data
interface DetectorObject {
  mac: string;
  ip: string;
  last_active: string;
  online: boolean;
  status: {
      site_name: string;
      gps_fix: boolean;
      gps_lat: number;
      gps_long: number;
      up_since: string;
      memory: string;
      fw_version: string;
      test_mode: boolean;
      thr: string;
      linux: boolean;
  };
}
const Backend: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [detectorData, setDetectorData] = useState<DetectorObject[] | null>([]);
  const [reportGenAt, setReportGenAt] = useState<string | null>(null);
  const [detectorDataBR, setDetectorDataBR] = useState<DetectorObject[] | null>(
    []
  );
  const [reportGenAtBR, setReportGenAtBR] = useState<string | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/apidet/detector/monitoring', {
          credentials: 'same-origin',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // If the raw response is a string, parse it to JSON
        const cleanedString = data.dataUSA.replace(/\\n/g, '').trim(); // Clean up the string
        const jsondata = JSON.parse(cleanedString);

        // Remove the generated_at key if it exists
        if (jsondata.generated_at) {
          setReportGenAt(jsondata.generated_at);
          delete jsondata.generated_at; // Remove the key from the object
        }
        setDetectorData(jsondata);

        // Same for BR Data
        // If the raw response is a string, parse it to JSON
        const cleanedStringBR = data.dataBR.replace(/\\n/g, '').trim(); // Clean up the string
        const jsondataBR = JSON.parse(cleanedStringBR);

        // Remove the generated_at key if it exists
        if (jsondataBR.generated_at) {
          setReportGenAtBR(jsondataBR.generated_at);
          delete jsondataBR.generated_at; // Remove the key from the object
        }
        setDetectorDataBR(jsondataBR);
        setIsLoading(false);
      } catch (error) {
        //USA
        setDetectorData([]);
        setReportGenAt(null);
        //BR
        setDetectorDataBR([]);
        setReportGenAtBR(null);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Fetch data every 5 seconds
    const interval = setInterval(fetchData, 5000);// temp

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  const [preferredColumnsOrder, setPreferredColumnsOrder] = useState([]);

  const [selectedView, setSelectedView] = useState('report'); // Initial view is set to "report"

  const handleViewChange = (view: React.SetStateAction<string>) => {
    setSelectedView(view);
  };
  const FNNlogo = require('./assets/FNN_LOGO_letters_Navbar.png');

  // Create a custom icon
  const onlineIcon = L.icon({
    iconUrl: require('./assets/mapsmarker.png'), // Path to the custom icon image
    iconSize: [80, 80], // Size of the icon image
    iconAnchor: [40, 80], // Anchor point of the icon image (bottom center)
    popupAnchor: [0, -80], // Anchor point for popups relative to the icon image
  });

  const offlineIcon = L.icon({
    iconUrl: require('./assets/mapsmarker_bw.png'),
    iconSize: [80, 80],
    iconAnchor: [40, 80],
    popupAnchor: [0, -80],
  });

  const navigate = useNavigate();

  const { username } = useAuth();

  const handleAuthRedirection = () => {
    navigate('/login'); // Replace with your desired route
  };

  const [expandedRow, setExpandedRow] = useState(null); // Ref to store the expanded row

  return (
    <div className="AppBackend backend">
      <header className="flex justify-between px-5 header">
        <a href="/">
          <img src={FNNlogo} className="App-logo" alt="FNN logo" />
        </a>
        <div className="display-flex">
          <button
            className={`bnt-header ${selectedView === 'map' ? 'active' : ''}`}
            onClick={() => handleViewChange('map')}
          >
            Map
          </button>
          <button
            className={`bnt-header ${
              selectedView === 'report' ? 'active' : ''
            }`}
            onClick={() => handleViewChange('report')}
          >
            Report
          </button>
        </div>
        <div
          onClick={handleAuthRedirection}
          className="flex gap-0 flex-col items-center text-base text-gray-400 hover:text-white cursor-pointer"
        >
          <svg
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: '25px', height: '25px' }}
          >
            <g id="SVGRepo_iconCarrier">
              <path
                d="M8 7C9.65685 7 11 5.65685 11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7Z"
                fill="#e5640c"
              />
              <path
                d="M14 12C14 10.3431 12.6569 9 11 9H5C3.34315 9 2 10.3431 2 12V15H14V12Z"
                fill="#e5640c"
              />
            </g>
          </svg>
          {username}
        </div>
      </header>
      {/* 
      {selectedView === "map" && (
        <MapView
          detectorData={detectorData}
          onlineIcon={onlineIcon}
          offlineIcon={offlineIcon}
        />
      )} */}
      {selectedView === 'report' && isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className={'body'}>
          <ReportView
            detectorData={detectorData}
            reportGenAt={reportGenAt}
            preferredColumnsOrder={preferredColumnsOrder}
            expandedRow= {expandedRow}
            setExpandedRow={setExpandedRow}
          />
          <ReportView
            detectorData={detectorDataBR}
            reportGenAt={reportGenAtBR}
            preferredColumnsOrder={preferredColumnsOrder}
            expandedRow = {expandedRow}
            setExpandedRow={setExpandedRow}
          />
        </div>
      )}
    </div>
  );
};

export default Backend;
