import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import axios from 'axios';
import { useState } from 'react';
import '../css/contact-form.css';
import whatsAppIcon from '../img/contact/whatsapp-xxl.png';
const iframeSrc =
  'https://d427d68c.sibforms.com/serve/MUIFAJrCngk7ZDAIse3UCkzMFKGmV74MHzC9Z9JFd219LpgTu4txsiNQnsjBKH-lHUNaFAyaK6QKZ3i_v828qTLwXP6PTcUiHzFLR_0dgQ6I9ZfVFrBYhLNIdtV3bblePE-eQiM1tMUwO6sLZASw-gNuL8XKmd1j50NqCsxgNAJBTHlfl1XODSujop9QlT4fVZvZbHwifmKvbrT_%22';

export default function ContactForm() {
  return (
    <div className="relative bg-opacity-10">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2" />
      </div>
      <div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-1 ">
        <div className="contactInfo px-4 sm:px-6 lg:col-span-1 lg:px-4 xl:pr-12">
          <div className="mx-auto max-w-lg">
            <h2 className="text-2xl font-bold tracking-tight text-gray-100 sm:text-3xl">
              Contact Us
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-300">
              Want to get in contact? Fill out this form and we&#39;ll get back
              to you as soon as we can.
            </p>

            <dl className="mt-8 text-base text-gray-500">
              <h1>HELIOS POMPANO INC</h1>
              <div>
                <p className="mt-3 text-lg leading-6 text-gray-300">
                  Headquarters
                </p>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p>747 SW 2nd Avenue Suite 169</p>
                  <p>Gainesville, FL 32601</p>
                  <p>United States of America</p>
                </dd>
              </div>
              <div>
                <p className="mt-3 text-lg leading-6 text-gray-300">
                  South Florida Office
                </p>
                <dt className="sr-only">Postal address</dt>
                <dd>
                  <p>2226 N.Cypress Bend Dr. #209</p>
                  <p>Pompano Beach, Fl 33069</p>
                  <p>United States of America</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="h-6 w-6 flex-shrink-0 text-gray-300"
                    aria-hidden="true"
                  />
                  <img
                    className="h-6 w-6 flex-shrink-0 text-gray-300"
                    src={whatsAppIcon}
                    alt="Whatsapp"
                    style={{ opacity: 0.8 }}
                  />

                  <span className="ml-3">+1 (954)-681-1770</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <EnvelopeIcon
                    className="h-6 w-6 flex-shrink-0 text-gray-300"
                    aria-hidden="true"
                  />
                  <span className="ml-12 ps-2">
                    {' '}
                    info@fireneuralnetwork.com
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
        <div className="iframe-container">
          <iframe
            className="iframe"
            src={iframeSrc}
            frameBorder="0"
            scrolling="no"
            allowFullScreen
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '100%',
            }}
          ></iframe>
        </div>
      </div>
    </div>
  );
}
