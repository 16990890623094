import React from "react";
import ReportComponent from "../components/Report";

function ReportView (props: { detectorData: any; reportGenAt: any; preferredColumnsOrder: any; expandedRow : any, setExpandedRow: any}) {
  return (
      <div className="App-component">
        <ReportComponent detectorData={props.detectorData} reportGenAt={props.reportGenAt} preferredColumnsOrder={props.preferredColumnsOrder} expandedRow = {props.expandedRow} setExpandedRow={props.setExpandedRow}/>
      </div>
  );
}

export default ReportView ;
