import CardGrid, { Card } from '../components/card-grid';

function Stories() {
  const stories: Card[] = [
    // {
    //   title: 'High-Risk-Lightning Detection Can Prevent Disaster',
    //   href: 'https://storymaps.arcgis.com/stories/5437342dd9374aa3910504749265563f',
    //   date: 'Nov 22, 2022',
    //   datetime: '2022-11-22',
    //   imageUrl: require('../img/stories/bert-nass.jpg'),
    //   newTab: true,
    // },
    // {
    //   title: 'Welcome Fire',
    //   href: '/welcomefire',
    //   date: 'Sep 09, 2023',
    //   datetime: '2023-09-09',
    //   imageUrl: require('../img/stories/welcome-fire-ll.jpg'),
    //   newTab: false,
    // },
    // {
    //   title: '2023 Fires in Recap',
    //   href: 'https://storymaps.arcgis.com/stories/df47c927f1e642b8ae2bc4dcde8d8496',
    //   date: 'Dec 13, 2023',
    //   datetime: '2023-12-13',
    //   imageUrl: require('../img/stories/firesrecap.png'),
    //   newTab: false,
    // },
  ];

  return (
    <CardGrid
      // header={'Stories'}
      // subheader={'Internal publications by the Fire Neural Network Team'}
      cards={stories}
    />
  );
}

export default Stories;
