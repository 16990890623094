import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

interface AuthContextType {
  username: string;
  isAuthenticated: boolean;
  error: string;
  getPermissions: () => Promise<{ groups: Record<string, any> }>; // Change return type
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  whoami: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [username, setUsername] = useState('');
  const [error, setError] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    getSession();
  }, []);

  const getSession = async () => {
    try {
      const res = await fetch('/apidet/session', {
        credentials: 'same-origin',
      });
      const data = await res.json();
      setIsAuthenticated(data.isauthenticated);
      setUsername(data.username);
    } catch (err) {
      console.error(err);
    }
  };

  const getPermissions = async () => {
    try {
      const res = await fetch('/apidet/permissions', {
        credentials: 'same-origin',
      });
      const data = await res.json();
      setIsAuthenticated(data.isauthenticated);
      return data.groups;
    } catch (err) {
      console.error(err);
    }
  };

  const whoami = async () => {
    try {
      const res = await fetch('apidet/whoami', {
        headers: { 'Content-Type': 'application/json' },
        credentials: 'same-origin',
      });
      const data = await res.json();
      setUsername(data.username);
    } catch (err) {
      console.error(err);
    }
  };

  const getCsrfToken = (): string | null => {
    const metaTag = document.querySelector('meta[name="csrf-token"]');
    return metaTag ? metaTag.getAttribute('content') : null; // Return null if the meta tag is not found
  };

  const login = async (username: string, password: string) => {
    try {
      let csrfToken = cookies.get('csrftoken'); // Get the CSRF token from cookies

      if (!csrfToken) {
        csrfToken = getCsrfToken();
        if (!csrfToken) {
          console.error('CSRF token not found');
          return; // Handle the absence of the CSRF token appropriately
        }
      }
      const res = await fetch('/apidet/login/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': csrfToken,
        },
        credentials: 'same-origin',
        body: JSON.stringify({ username, password }),
      });
      if (!res.ok) throw new Error('Login failed');
      const data = await res.json();
      setIsAuthenticated(true);
      setUsername(username);
      setError('');
    } catch (err) {
      console.error(err);
      setError('Wrong username or password');
    }
  };

  const logout = async () => {
    try {
      const res = await fetch('/apidet/logout', { credentials: 'same-origin' });
      if (!res.ok) throw new Error('Logout failed');
      setIsAuthenticated(false);
      setUsername('');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        username,
        isAuthenticated,
        error,
        getPermissions,
        login,
        logout,
        whoami,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
