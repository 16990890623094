import AnimatedCounter from '../components/animated-counter';
import '../css/hrl-detector.css';
import Gallery from '../components/gallery';
import {
  LazyLoadImage,
  LazyLoadComponent,
} from 'react-lazy-load-image-component';
import React, { useState,useEffect } from 'react';

type DetectorPhoto = {
  imgAlt: string;
  img: any;
};


function RenderQuoteSvg() {
  return (
    <svg
      className="absolute top-0 left-0 h-8 w-8 -translate-x-3 -translate-y-6 transform text-orange-900"
      fill="currentColor"
      viewBox="0 0 32 32"
      aria-hidden="true"
    >
      <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
    </svg>
  );
}

function HrlDetector() {
  const detectorStats = {
    area: {
      name: 'Detection Time, sec',
      value: 40 - 1,
    },
    hrls: {
      name: 'Total Area Covered per Detector Array (6), km<sup>2</sup>',
      value: 4000,
    },
    fires: {
      name: 'Detection Accuracy, m',
      value: 40 - 1,
    },
  };

  const [galleryData, setGalleryData] = useState<DetectorPhoto[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  
  useEffect(() => {
    const fetchGalleryPhotos = async () => {
      try {
        const response = await fetch('/api/detectorgallery', {
          method: 'GET',
          credentials: 'same-origin',
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data: DetectorPhoto[] = await response.json();
        setGalleryData(data); // Assign the fetched gallery data to state
      } catch (err) {
        // Type assertion to 'Error' to access the message property
        if (err instanceof Error) {
          setError(err.message); // Handle error
        } else {
          setError('An unknown error occurred'); // Fallback for unknown errors
        }
      } finally {
        setLoading(false); // End loading state
      }
    };

    fetchGalleryPhotos();
  }, []); // Empty dependency array to run once on mount

  const detectorDimmensions = [
    {
      title: 'HEIGHT',
      metric: '1 m',
      imperial: '3.28 ft',
    },
    {
      title: 'WEIGHT',
      metric: '20 kg',
      imperial: '46 lb',
    },
    {
      title: 'DIAMETER',
      metric: '0.5 m',
      imperial: '1.64 ft',
    },
    {
      title: 'WATTAGE',
      metric: '7.5 W',
      imperial: '7.5 Wh',
    },
  ];

  const reviewData = [
    {
      quote:
        'Lightning ignition was responsible for over 90% of the area burned in the 2020 Black Summer fires. We need the FNN™ detectors and service nationally. We placed an order.',
      img: require('../img/detector/marta.jpeg'),
      name: 'Dr. Marta Yebra',
      title: 'Director, Australian Bushfire Initiative',
    },
    {
      quote:
        'We currently house FNN™ detectors and use the AI-based HRL™ service. We aim to continue to facilitate the implementation.',
      img: require('../img/detector/john.png'),
      name: 'John Raulerson',
      title: 'Florida Chief of Forest Protection',
    },
  ];

  const DetectorBg = require('../img/detector/forestvid.mp4');
  const DetectorBg_LazyLoadImg = require('../img/detector/forestvid-lazyload.jpg');

  const DetectorInfinity = require('../img/detector/Detector_Infinity.png');

  const detectvideo = require('../img/detector/SpinningHRLDetector.mp4');
  const detectvideo_LazyLoadImg = require('../img/detector/SpinningHRLDetector-lazyload.png');

  function BackgroundVideo() {

    // This is used to check if the video loaded and display a lazy image while the video loads
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    
    return (
      <>
        <div className="relative bg-black pb-32">
          <div className="absolute inset-0">
            {/* Forest Video */}
          {!isVideoLoaded && (
            <LazyLoadComponent
              placeholder={<img src={DetectorBg_LazyLoadImg} alt="" />}
            >
            <LazyLoadImage
              src={DetectorBg_LazyLoadImg} 
              alt="=" 
              className="absolute inset-0 w-full h-full object-cover"
            />
            </LazyLoadComponent>
            )}

              {/* Forest Video */}
              <video
                className={`h-full w-full object-cover ${isVideoLoaded ? '' : 'hidden'}`}
                src={DetectorBg}
                autoPlay
                loop
                muted
                onLoadedData={() => setIsVideoLoaded(true)} // Remove this line if you would like to look at the lazy image
              />
            <div
              className="absolute inset-0 mix-blend-multiply"
              aria-hidden="true"
            />
          </div>
          {/* Page Title */}
          <div className="relative mx-auto max-w-7xl pt-24 px-4 sm:py-32 sm:pb-16 sm:px-6 lg:px-8">
            <h1 className="text-4xl font-bold tracking-tight text-white md:text-5xl lg:text-6xl">
              High-Risk-Lightning™
            </h1>
            <p className="text-6xl font-bold tracking-tight text-white md:text-7xl lg:text-8xl">
              Detector
            </p>
          </div>
        </div>

        <section
          className="relative mx-auto max-w-7xl px-4 pb-8 sm:px-6 lg:px-8"
          aria-labelledby="contact-heading"
        >
          <div className="grid grid-cols-1 lg:grid-cols-3 lg:gap-y-0 lg:gap-x-8">
            {Object.entries(detectorStats).map(([, value]) => (
              <div key={value.name} className="flex flex-col shadow-xl">
                <div className="relative flex-1 px-6 py-8 md:px-8 mx-auto text-center">
                  <h3 className="text-4xl md:text-6xl font-medium text-gray-100">
                    <AnimatedCounter value={value.value} />
                  </h3>
                  <p
                    className="mt-4 text-base text-gray-400"
                    dangerouslySetInnerHTML={{ __html: value.name }}
                  ></p>
                </div>
              </div>
            ))}
          </div>
        </section>
      </>
    );
  }

  function OverviewDetector() {
    return (
      <>
        <div className="wrapper">
          <div className="main">
            {/* Text next to Rotating Detector */}
            <p className="about-detect">
              The FNN™ on-site High-Risk-Lightning™ detectors allow for
              real-time fire ignition tracking with an accuracy of 40 meters
              within 40 seconds. Information about the lightning strike and
              environmental data are fed into our proprietary neural network to
              determine if the strike was a High-Risk-Lightning™ (HRL™) strike
              or not. Firefighting services can rely on this to efficiently
              allocate their resources for containment.
            </p>
            <ul className="about-detect">
              {/* Detector Stats */}
              <h1 className="overview">Overview</h1>
              {detectorDimmensions.map((dimmension) => (
                <li
                  key={dimmension.title}
                  className="flex justify-between py-4 w-full text-base font-medium text-gray-300 w-100"
                >
                  <p>{dimmension.title}</p>
                  <p className="align-right">
                    {dimmension.metric}{' '}
                    <span className="text-gray-500">
                      / {dimmension.imperial}
                    </span>
                  </p>
                </li>
              ))}
            </ul>
          </div>
          {/* Rotating Detector */}
          <div className="sidebar">
            <div className="d-flex justify-content-center align-items-center overflow-hidden">
              <LazyLoadComponent
                placeholder={<img src={detectvideo_LazyLoadImg} alt="" />}
              >
                {/* Detector Video */}
                <video
                  className="h-full w-full"
                  src={detectvideo}
                  autoPlay
                  loop
                  muted
                />
              </LazyLoadComponent>
            </div>
          </div>
        </div>
      </>
    );
  }

  function PerformanceDescription() {
    return (
      <>
        <div className="relative">
          {/* Performance and Speed Section */}
          <div className="per-speed-section">
            <div className="Overlay-right">
              <div>
                <h2 className="performance-title">PERFORMANCE</h2>
                <p>
                  Fire Neural Network™ cross-corroborates NOAA GLM lightning
                  satellite data with its real-time lightning database to
                  pinpoint potential fires. In addition, it uses multiple bands
                  to differentiate between regions of cloud and no-cloud to
                  allow for higher accuracy.
                </p>
              </div>
              <div>
                <h2 className="performance-title">SPEED</h2>
                <p>
                  Example: Fire Neural Network™ detected a fire in real-time on
                  September 5th, 2019. By the time authorities detected the fire
                  24 hours later, the devastation was more than 10 square miles.
                  Fires spread quickly and become hard to contain, so a 24-hour
                  detection time is just not good enough! Fire Neural Network™
                  delivers warnings within 40 seconds. When it comes to
                  detecting fires, speed is everything!
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  function DetectorNetwork() {
    return (
      <>
        {/* Detectors going into Background */}
        <div className="detector-infinity-bg">
          <img src={DetectorInfinity} alt="Drawings of the HRL™ Detector" />
          <p>
            These detectors are installed as a network, with a minimum of 6
            detectors required for proper location accuracy and detection
            efficiency.
          </p>
        </div>
      </>
    );
  }

  function Review(props: any) {
    const { quote, img, name, title } = props;
    return (
      <>
        <blockquote className="mt-6 md:flex md:flex-grow md:flex-col">
          <div className="relative text-lg font-medium text-white md:flex-grow">
            {RenderQuoteSvg()}
            <p className="relative">&quot;{quote}&quot;</p>
          </div>
          <footer className="mt-8">
            <div className="flex items-start">
              <div className="inline-flex flex-shrink-0 rounded-full border-2 border-white">
                <img className="h-12 w-12 rounded-full" src={img} alt="" />
              </div>
              <div className="ml-4">
                <div className="text-base font-medium text-white">{name}</div>
                <div className="text-base font-medium text-orange-200">
                  {title}
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      </>
    );
  }

  const reviewElements = reviewData.flatMap((review, index) => [
    // Render a divider between each review
    index % 2 === 0 && (
      <div
        key={`div-${index}`}
        className=" py-12 px-4 sm:px-6 md:flex md:flex-col md:border-r md:border-orange-900 md:py-16 md:pl-0 md:pr-10 lg:pr-16"
      >
        <Review
          key={review.name}
          quote={review.quote}
          img={review.img}
          name={review.name}
          title={review.title}
        />
      </div>
    ),
    (index + 1) % 2 === 0 && (
      <div
        key={`div-${index}`}
        className=" py-12 px-4 sm:px-6 md:py-16 md:pr-0 md:pl-10 lg:pl-16"
      >
        <Review
          key={review.name}
          quote={review.quote}
          img={review.img}
          name={review.name}
          title={review.title}
        />
      </div>
    ),
  ]);



  return (
    <div>
      <BackgroundVideo />
      <OverviewDetector />
      <PerformanceDescription />
      <DetectorNetwork />
      {/* Review Quote Section */}
      <div className="my-24">
        <section className=" bg-opacity-10">
          <div className="mx-auto max-w-7xl md:grid md:grid-cols-2 md:px-6 lg:px-8">
            {reviewElements}
          </div>
        </section>
      </div>
      <Gallery data={galleryData} isLoading={loading}/>
    </div>
  );
}

export default HrlDetector;
