// If offline the row color will be grey
export const getColumnColor = (online) => {
  if (online) {
    return "online-column"; // CSS class name for the online column
  } else if (!online) {
    return "offline-column"; // CSS class name for the offline column
  }
  return ""; // Empty string for other columns
};

// If more than 5 min since last report, color will be red
export const getHeaderColor = (reportGenAt) => {
  if (!reportGenAt) {
    return ""; // Return empty string if reportGeneratedAt is null
  }
  const now = new Date();
  const reportGenAtDate = new Date(reportGenAt);
    const fiveMinutes = 5 * 60 * 1000; // 5 minutes in milliseconds
    const timeSinceReport = now.getTime() - reportGenAtDate.getTime();
  if (timeSinceReport > fiveMinutes) {
    return "red"; // CSS class name for red color
  } 
  return ""; // Empty string for other columns
};


// Change single row/column entries CSS according to value only if they are online
export const getColumnClassName = (column, value, online) => {
  if (online) {
    if (column === "Test Mode" && value) {
      return "warning-column";
    }

    if (column === "GPS %" && value >= 50 && value <= 75) {
      return "warning-column";
    }

    if (column === "GPS %" && value < 50) {
      return "error-column";
    }

    if (column === 'Memory') {
      const memoryValue = parseFloat(value.replace('%', ''));
  
      if (memoryValue >= 50 && memoryValue <= 70) {
        return 'warning-column';
      } else if (memoryValue > 70) {
        return 'error-column';
      }
    }

  }

  return "";
};
