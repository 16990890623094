import ContactForm from '../components/contact-form';

function Contact() {
  return (
    
    <div className="text-white">
      <ContactForm />
    </div>
  );
}

export default Contact;
