import React, { useState } from 'react';
import { useAuth } from '../Auth';
import LoginRoutes from './loginpageroutes';

const Login: React.FC = () => {
  const { login, logout, isAuthenticated, error, username } = useAuth();
  const [loginUsername, setLoginUsername] = useState('');
  const [password, setPassword] = useState('');


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await login(loginUsername, password);
  };

  return (
    <div
      className="d-flex py-5 my-5 justify-content-center align-items-center"
      style={{ backgroundColor: '#1c1c1c', minHeight: "55vh" }}
    >
      <div className="text-white text-white text-center">
        {isAuthenticated ? (
          <>
            <div className='py-4'>
              <h1 className='py-1'>Welcome {username}!</h1>
              <button onClick={logout} className="btn btn-danger ms-2">
                Log out
              </button>
            </div>
            <LoginRoutes />
          </>
        ) : (
          <>
            <h1 className="text-center">Login</h1>
            <div className="row justify-content-center">
              <div className="">
                <form
                  onSubmit={handleSubmit}
                  className="bg-dark text-white p-4 rounded"
                >
                  <div className="mb-3">
                    <label htmlFor="username" className="form-label">
                      Username
                    </label>
                    <input
                      type="text"
                      id="username"
                      className="form-control bg-secondary text-white"
                      value={loginUsername}
                      onChange={(e) => setLoginUsername(e.target.value)}
                      placeholder="Enter your username"
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <input
                      type="password"
                      id="password"
                      className="form-control bg-secondary text-white"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Enter your password"
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn background-primary"
                    style={{ color: 'white', width: '100%' }}
                  >
                    Login
                  </button>
                </form>
                {error && (
                  <div className="alert alert-danger mt-3">{error}</div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Login;
