import { EnvelopeIcon } from '@heroicons/react/24/outline';
import '../css/subscribe-form.css';

const iframeSrc = "https://69e32913.sibforms.com/serve/MUIFACLWcvr89XmFmeiRHA5f_uAeph3CDVStAqU4eawV8TMfzSl6fbNz2AHfYWOqWQcLUZiwSpujfJVxxBJ4n0K2FKNIqVrHLgCOKpr0bGT0ZTmTg9LTJ55dcTZ2TJHBLlmFwztsI62QFIy7ERePVZ-DKJzXOPL30n3wxErAiisMjRhfvJF8AVfX9D9Fcl_wVcg0qRXeKfWrnQEk";


export default function SubscribeForm() {
  return (
    <div className="iframe-container">
          <iframe className="iframe" src={iframeSrc} frameBorder="0" scrolling="no" allowFullScreen style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto', maxWidth: '100%' }}></iframe>
    </div>
  );
}
