import '../css/welcome-fire.css';
import welcomeFire from '../img/stories/welcomefire.png';
import welcomeFireLazyLoad from '../img/stories/welcomefire-lazyload.jpg';
import {
  LazyLoadImage,
  LazyLoadComponent,
} from 'react-lazy-load-image-component';
import Map from '../components/map';

export default function welcomefire() {
  return (
    <div className="page">
      <div className="top-grid">
        <LazyLoadImage
          alt="forrest trees"
          src={welcomeFire} // use normal <img> attributes as props
          effect="opacity"
          placeholderSrc={welcomeFireLazyLoad}
        />
        <div className="flex-container-wf">
          <p className="welcomeFire">Welcome Fire</p>
          <p className="welcomeFireDescription">
            “Saturday, September 9th I was dispatched to a wildfire. Fire Flight
            reported a quarter acre fire off of SR11. As I arrived on scene I
            noticed the fire department was already there and had put water on
            the fire. I then unloaded the dozer and put in a control line around
            the fire to secure it. Once I was done I tracked in on foot and it
            was 200ft x 200ft. I then investigated the cause and came across two
            trees side by side that had been struck by lightening. I returned
            the next day to make sure everything was still secure and no threats
            to the dozer line. The fire was dead out.”
          </p>
          <div className="author-grid">
            <p> -Jason Longfellow</p>
            <p style={{ fontSize: 'var(--font-x-small)' }}>
              FFS Forest Area Supervisor
            </p>
          </div>
        </div>
      </div>
      <div className="bottom-grid">
        <div className="label">Report Date:</div>
        <div className="value">09-09-2023</div>
        <div className="label">Reported By:</div>
        <div className="value">Aircraft</div>
        <div className="label">Location:</div>
        <div className="value">Bunnell, FL</div>
        <div className="label">Cause:</div>
        <div className="value">Lightning</div>
      </div>
      <Map
        center={{
          lat: 29.45305556,
          lng: -81.28277778,
        }}
      />
    </div>
  );
}
