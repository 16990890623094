export const mainRoutes = [
  { name: 'HRL Detector', to: '/hrl-detector' },
  { name: 'HRL Mapping', to: '/hrl-mapping' },
  { name: 'HRL Firebird', to: '/hrl-firebird' },
];

export const secondaryRoutes = [
  { name: 'Mission', to: '/mission' },
  { name: 'News', to: '/news' },
  { name: 'Hotspot', to: '/hotspot' },
  // { name: 'Stories', to: '/stories' },
  { name: 'Contact Us', to: '/contact' },
  { name: 'Open Opportunities', to: '/opportunities' },
  { name: 'GatorX', to: '/gatorX' },

];

export const allRoutes = [
  { name: 'Home', to: '/' },
  ...mainRoutes,
  ...secondaryRoutes,
];
