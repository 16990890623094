import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

interface Props {
  center: {
    lat: number;
    lng: number;
  };
}

const containerStyle = {
  width: '100%',
  height: '600px',
};

function Map(props: Props) {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyCLI8EL_zxvJPFEk-ro0K-g5IUFH91YGz4',
  });

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={props.center}
      zoom={12}
      mapTypeId="satellite"
    >
      <Marker position={props.center} />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default Map;
