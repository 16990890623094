import { LazyLoadImage } from 'react-lazy-load-image-component';

const Video = require('../img/mission/FNN-1080p-230715.mp4');
const backgroundImage = require('../img/mission/ourmission-bgd.png');
const backgroundImageLazyLoad = require('../img/mission/ourmission-bgd-ll.jpg');


function Mission() {
  return (
    <div>

      <div className="relative overflow-hidden">
      <LazyLoadImage
        src={backgroundImage}
        placeholderSrc={backgroundImageLazyLoad}
        alt={'Background Image'}
        style={{
          position: 'absolute',  
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: 0,
          objectFit: 'cover' 
        }}
      />
        {/* Mission Background Image */}
        <div className="grid-mission">
          {/* Mission Statement */}
          <h1 className="our-mission font-bold tracking-tight text-white">
            Our <span className="textColor">Mission</span> is to Save Lives,
            Protect Property, and Fight Against Climate Change
          </h1>
          {/* Mission Youtube Video */}
          <div className="youtube-mission">
            <iframe
              width="150vh"
              height="315"
              src="https://www.youtube.com/embed/zvmm8-LJYv8"
              title="FNN YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          {/* Mission Texts */}
          <div className="grid-block text-white">
            <p className="block-overlay">
              Fire Neural Network™ utilizes novel lightning detectors capable of
              measuring the attributes of High-Risk-Lightning™ (HRL™), including
              long continuing currents and charge transfer that lead to heating.
              Fire agencies can efficiently target this small proportion of HRL™
              strikes. In addition, the smart detectors are able to map
              lightning in 3D, leading to superior detection efficiency and
              location accuracy, thereby facilitating both a quicker and more
              efficient inspection of high-risk ground strikes.
            </p>
            <p className="block-overlay">
              This outcome is accomplished using a machine learning algorithm to
              analyze electric field waveforms from our lightning detectors to
              identify the presence of long-continuing currents. This unique
              characterization of lightning strikes is combined with information
              about weather and fuel attributes derived from satellite and
              airborne sensors to enable a more thorough understanding of the
              conditions under which lightning ignitions occur.
            </p>
            
          </div>
          <video className="video-diagram" controls>
            <source src={Video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      
    </div>
  );
}

export default Mission;
