import ApplyForm from '../components/apply-form';

function Opportunities() {
  return (
    <div className="pageapply">
      <ApplyForm />
    </div>
  );
}

export default Opportunities;