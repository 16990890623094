/* eslint-disable @typescript-eslint/no-explicit-any */
import { FireDetail } from '../api/fires';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { format } from 'date-fns';

interface Props {
  fire: FireDetail;
}

function WhatTheAiSeesChart(props: Props) {
  const verticalLinePlugin = {
    getLinePosition: function (chart: any, pointIndex: number) {
      const meta = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
      const data = meta.data;
      return data[pointIndex]._model.x;
    },
    renderVerticalLine: function (chartInstance: any, pointIndex: number) {
      const lineLeftOffset = this.getLinePosition(chartInstance, pointIndex);
      const scale = chartInstance.scales['y-axis-0'];
      const context = chartInstance.chart.ctx;

      // render vertical line
      context.beginPath();
      context.strokeStyle = '#ff0000';
      context.moveTo(lineLeftOffset, scale.top);
      context.lineTo(lineLeftOffset, scale.bottom);
      context.stroke();

      // write label
      context.fillStyle = '#ff0000';
      context.textAlign = 'center';
      context.font = '15px Helvetica';
      // @TODO: Make this put the text on the left or right correctly
      context.fillText(
        'FIRE DETECTED',
        lineLeftOffset + scale.right,
        (scale.bottom - scale.top) / 2 + 0.25 * scale.top
      );
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    afterDatasetsDraw: function (chart: any, _easing: any) {
      if (chart.config.lineAtIndex) {
        chart.config.lineAtIndex.forEach((pointIndex: number) =>
          this.renderVerticalLine(chart, pointIndex)
        );
      }
    },
  };

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    elements: {
      point: {
        radius: 0,
        hoverRadius: 4,
        hitRadius: 4,
      },
    },
    plugins: {
      title: {
        display: true,
        font: {
          size: 30,
        },
        color: 'white',
        text: 'What the Artificial Intelligence Sees',
      },
      verticalLinePlugin,
    },
    tooltips: {
      mode: 'index',
    },
    type: 'line',
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    scales: {
      // @TODO: This isn't letting me set the time scale how we want to
      // x: {
      //   type: 'time',
      //   time: {
      //     unit: 'hour' as const,
      //   },
      //   adapters: {
      //     date: {
      //       locale: enUS
      //     }
      //   }
      // },
      y: {
        stacked: false,
        position: 'left' as const,
        title: {
          display: true,
          text: 'Near Infrared Radiance',
          color: 'rgba(71, 103, 120, 1)',
          font: {
            size: 20,
          },
        },
      },
      y1: {
        stacked: false,
        position: 'right' as const,
        title: {
          display: true,
          text: 'Alarm Level',
          color: 'rgba(253, 155, 160, 1)',
          font: {
            size: 20,
          },
        },
      },
    },
    // TODO: This needs to actually get set
    lineAtIndex: 40,
  };

  // The actual data to draw the chart with
  const chartData = {
    labels: props.fire.time_graph_pts.map((time_point: string) => {
      return format(new Date(time_point), 'h:mm a');
    }),
    datasets: [
      {
        label: 'Alarm Threshold',
        lineTension: 0,
        data: props.fire.cloud_graph_pts.map((cloud_pt: boolean, i: number) => {
          return {
            x: new Date(props.fire.time_graph_pts[i]),
            y: cloud_pt ? 0.55 : 0.18,
          };
        }),
        yAxisID: 'y1',
        backgroundColor: 'rgb(0, 0, 0)',
        borderColor: 'rgb(220, 227, 223)',
        borderWidth: 1,
        borderDash: [10, 5],
        steppedLine: true,
      },
      {
        label: 'Alarm Level',
        lineTension: 0,
        data: props.fire.diff_graph_pts.map((diff_pt: number, i: number) => {
          return {
            x: new Date(props.fire.time_graph_pts[i]),
            y: diff_pt,
          };
        }),
        yAxisID: 'y1',
        backgroundColor: ['rgba(0, 0, 0, 0)'],
        borderColor: ['rgba(253, 155, 160, 1)'],
        borderWidth: 4,
      },
      {
        label: 'Predicted Near Infrared',
        lineTension: 0,
        data: props.fire.pred_graph_pts.map((pred_pt: number, i: number) => {
          return {
            x: new Date(props.fire.time_graph_pts[i]),
            y: pred_pt,
          };
        }),
        backgroundColor: ['rgba(0, 253, 220, 0.3)'],
        fill: true,
        borderColor: ['rgba(0, 253, 220, 1)'],
        borderWidth: 3,
      },
      {
        label: 'Actual Near Infrared',
        lineTension: 0,
        data: props.fire.actual_7_graph_pts.map(
          (actual_pt: number, i: number) => {
            return {
              x: new Date(props.fire.time_graph_pts[i]),
              y: actual_pt,
            };
          }
        ),
        backgroundColor: ['rgba(220, 0, 115, 0.3)'],
        borderColor: ['rgba(255, 0, 170, 1)'],
        borderWidth: 3,
      },
    ],
  };

  return <Line options={options} data={chartData} height={200} />;
}

export default WhatTheAiSeesChart;
