import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FireDetail, getFireDetail } from '../api/fires';
import { format } from 'date-fns';
import WhatTheAiSeesChart from '../components/what-the-ai-sees-chart';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import Map from '../components/map';

function FireDetails() {
  // If a loading animation is desired, use the `isLoading` state
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState(true);
  const [fireDetail, setFireDetail] = useState<FireDetail>();

  const { id } = useParams();

  useEffect(() => {
    if (id)
      getFireDetail(id).then((res) => {
        setFireDetail(res[0]);
        setIsLoading(false);
      });
  }, [id]);

  const navigate = useNavigate();

  return (
    <div className="mx-auto max-w-7xl">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="py-8 flex flex-col">
          <div className="-my-2 mx-8 sm:mx-6 text-white">
            <div
              onClick={() => navigate(-1)}
              className="cursor-pointer inline-flex items-center justify-center rounded-md border border-transparent bg-gray-800 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-700 mb-4"
            >
              <ChevronLeftIcon className="h-6 w-6" aria-hidden="true" /> Go back
            </div>
            {fireDetail && (
              <>
                <div className="grid grid-cols-3 gap-6 mx-auto">
                  {/* Image of "zoomed in fire pictures" and fire details */}
                  
                  {/* This src link is causing some console errors, but in screen looks okay
                  It is  the temporary link to the img before it is loaded from database
                  After the error temp is formed, the current targer error is then substitude with the actual image from firedetail*/}
                  <img
                    src="https://www.fireneuralnetwork.com/media/fires_pics/temp_BfQMKUW.jpg" 
                    alt="Chart showing the alarm level of the fire"
                    className="col-span-3 md:col-span-2"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevent looping
                      if (fireDetail?.image)
                        currentTarget.src = fireDetail.image;
                      currentTarget.onerror = null;
                    }}
                  />
                  <div>
                    <h2 className="text-3xl font-bold tracking-tight text-white my-4">
                      Fire Details
                    </h2>
                    <p className="text-gray-300">
                      <strong>Latitude</strong>:{' '}
                      {fireDetail?.latitude.toFixed(3)}
                      <br />
                      <strong>Longitude</strong>:{' '}
                      {fireDetail?.longitude.toFixed(3)}
                      <br />
                      <strong>Time </strong>:{' '}
                      {format(
                        new Date(fireDetail.timestamp),
                        'MMM dd yyyy, h:mm a z'
                      )}
                      <br />
                      <strong>Cause</strong>: {fireDetail?.cause}
                    </p>
                  </div>
                </div>

                {/* What the AI sees graph */}
                <div
                  className="my-16 -mx-8 md:mx-0"
                  style={{
                    height: '800px',
                  }}
                >
                  <WhatTheAiSeesChart fire={fireDetail} />
                </div>

                <div className="w-full my-16">
                  <Map
                    center={{
                      lat: fireDetail.latitude,
                      lng: fireDetail.longitude,
                    }}
                  />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FireDetails;
