import gatorxheader from '../img/gatorX/gatorxheader.png';
import gatorXLogo from '../img/gatorX/gatorXLogo.png';
import lightningLogo from '../img/gatorX/lightning.png';
import quoteBG from '../img/gatorX/quoteBG.png';
import challenge1 from '../img/gatorX/challenge1.png';
import challenge2 from '../img/gatorX/challenge2.png';
import challenge3 from '../img/gatorX/challenge3.png';
import partner1 from '../img/gatorX/partner1.png';
import partner2 from '../img/gatorX/partner2.png';
import partner3 from '../img/gatorX/partner3.png';
import partner4 from '../img/gatorX/partner4.png';
import sidelines from '../img/gatorX/sidelines.png';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import '../css/gatorx.css';
import React, { useState, useEffect } from 'react';

function GatorX() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const sliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <div className="gatorX_main">
      <div className="flexContainer0">
        <div className="headerContainer">
          <img src={gatorxheader} className="headerImage" />
          {/* <img src={gatorXLogo} className="logoImage" /> */}
          {/* <div className="logoImage">
            <h2 className="font-medium text-white gatorXTitle">GATORX</h2>
            <h2 className="font-regular text-white sloganLabel">
              Find Fires Lighting Fast!
            </h2>
            <h2 className="font-regular text-orange sloganLabel money">
              $11,007,009
            </h2>
            <img src={lightningLogo} className="lightningLogo" />
            <img src={lightningLogo} className="lightningLogo2" />
          </div> */}
          <div className="boxGrid">
            <img src={lightningLogo} className="lightningLogo" />
            <div className="boxFlex">
              <h2 className="font-medium text-white gatorXTitle">GATORX</h2>
              <h2 className="font-regular text-white sloganLabel">
                Find Fires Lighting Fast!
              </h2>
              <h2 className="font-regular text-orange money">$11,007,009</h2>
            </div>
            <img src={lightningLogo} className="lightningLogo" />
          </div>
        </div>
        <div className="gridContainer0">
          <h2 className="text-white">
            Fire Neural Network (FNN), NVIDIA, and the University of Florida
            (UF) joined forces to form the GatorX team, registering for the
            prestigious XPRIZE Wildfire Competition.
          </h2>
          <h2 className="text-white">
            The XPRIZE wildfire competition is a three-year endeavor with a
            total prize pool of $11 million, focusing on the Autonomous Wildfire
            Response Track.
          </h2>
        </div>
        <div className="xprizeQuote">
          <img src={quoteBG} className='img-background'/>
          <div className="QuoteContainer">
            <p className="Quote">
              &quot;I’ve seen firsthand the devastating effects of wildfires on
              our local communities in California and around the world. I am
              thrilled to work with XPRIZE again and to see my early funding
              grow into a large prize that will attract innovative solutions to
              this challenging problem.&quot;
            </p>
            <p className="Quote2">Dr. Richard Merkin</p>
            <p className="Quote3">Seed Donor for XPRIZE Wildfire</p>
          </div>
        </div>
        <div className="flexContainer1">
          <h2 className="font-regular text-white whatIsTheChallenge">
            What is the Challenge?
          </h2>
          <div className="gridContainer1">
            <h2 className="text-2xl font-regular text-white sm:text-2xl">
              Globally, Extreme Wildfire Events (EWEs) are becoming more
              frequent. EWEs burn bigger, at a higher intensity, and can quickly
              sprawl out of control. While they represent only around 3% of all
              wildfires, EWEs account for 80% of total associated fire-damages
              and are increasingly contributing to global greenhouse gas
              emissions and worsening health impacts from pollutants.
            </h2>
            <h2 className="text-2xl font-regular text-white sm:text-2xl">
              These large and destructive wildfires wreak havoc on ecosystems
              worldwide, especially in the expanding Wildland-Urban Interface,
              where homes, businesses, and major infrastructure are most at
              risk, and where wildfire control and suppression activities are
              increasingly outperformed.
            </h2>
          </div>
          <div
            style={{
              alignSelf: 'flex-start',
            }}
          >
            <h2 className="moreInformation">More information:</h2>
            <a
              className="font-regular text-white xprizeLink"
              href="https://www.xprize.org/prizes/wildfire"
            >
              https://www.xprize.org/prizes/wildfire
            </a>
          </div>
          {isMobile ? (
            <Slider {...sliderSettings}>
              <img src={challenge1} alt="Challenge 1" />
              <img src={challenge2} alt="Challenge 2" />
              <img src={challenge3} alt="Challenge 3" />
            </Slider>
          ) : (
            <div className="gridContainer2">
              <img src={challenge1} alt="Challenge 1" />
              <img src={challenge2} alt="Challenge 2" />
              <img src={challenge3} alt="Challenge 3" />
            </div>
          )}
          <p className="missionstatement">
            Our <span style={{ color: 'orange' }}>Mission</span> is to Save
            Lives, Protect Property, and Fight Against Climate Change
          </p>
        </div>
        <div className="flexContainer2">
          <p className="gatorXPartnersTitle">
            <span style={{ color: 'orange' }}>GATORX</span> PARTNERS
          </p>
          <div className="flexContainer2-Row">
            <img src={partner3} />
            <img src={partner2} />
          </div>
          <div className="flexContainer2-Row1">
            <img src={partner4} />
            <img src={partner1} />
          </div>
        </div>
        <div className="gridContainer3">
          <img src={sidelines} className='inverted img-sides'/>
          <div className="flexContainer3">
            <p className="gatorXInternTeam">
              <span style={{ color: 'orange' }}>GATORX</span> INTERN TEAM
            </p>
            <p className="interndescription">
              Join one of FNN’s teams to help compete in the GATORX competition!
              Internships are paid and offered on a semester-to-semester basis.
              All majors are welcome to apply.
            </p>

            <p className="teamListTitle">Team List:</p>
            <div className="gridContainer4">
              <div className="teamList">
                <ul>
                  <li>&#8226; Content Management</li>
                  <li>&#8226; Graphic Design</li>
                  <li>&#8226; Marketing and Political Science</li>
                  <li>&#8226; GIS</li>
                </ul>
              </div>
              <div className="teamList">
                <ul>
                  <li>&#8226; Electrical Engineering</li>
                  <li>&#8226; Journalism</li>
                  <li>&#8226; Computer Science</li>
                  <li>&#8226; Web Development</li>
                </ul>
              </div>
            </div>
            <p className="interndescription">
              For more information, email:
              <a
                href="mailto:info@fireneuralnetwork.com"
                style={{ color: 'orange' }}
              >
                {' '}
                info@fireneuralnetwork.com
              </a>
            </p>

            <Link to="/opportunities">
              <button className="applyButton">APPLY</button>
            </Link>
          </div>
          <img src={sidelines} className='img-sides'/>
        </div>
      </div>
    </div>
  );
}

export default GatorX;
