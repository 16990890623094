import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div className="mx-auto max-w-7xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div className="text-center">
        <h2 className="text-4xl font-semibold text-lime-600">Oops!</h2>
        <p className="mt-1 text-2xl font-bold tracking-tight text-gray-200">
          An unexpected error has occured.
        </p>
        <p className="mx-auto mt-5 max-w-xl text-xl text-gray-300">
          {error.statusText || error.message}
        </p>
      </div>
    </div>
  );
}
